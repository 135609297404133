/* [START] [CR000022] [24-Jul-2019] [Kartik]
 * Master - Add "Audit Recommendations"
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { State } from '@progress/kendo-data-query';

import { Observable } from 'rxjs';

import { RequestApi } from 'src/app/utility-app/app-constants/app-request-api';
import { APIResult } from 'src/app/utility-app/models/api-result.interface';
import { APPDATA } from 'src/app/utility-app/app-constants/app.data';

import { LoaderMessageService } from 'src/app/utility-app/services/loader-message.service';

import RecommendationMasterModel from '../models/recommendation-master.model';

@Injectable({
  providedIn: 'root'
})

export class RecommendationMasterService {
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [{ field: 'recommendation', operator: 'contains', value: '' }]
    }
  };

  private data: any[] = [];

  constructor(private _http: HttpClient, private _loaderMessageService: LoaderMessageService) { }

  /* [START] [CR000025] [03-Feb-2020] [Kartik]
   * Auditor
   * c.	As an admin I can see new criteria for already added recommendations
   */
  public getRecommendationCriteriaList(): Observable<APIResult> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.get<any>(RequestApi.MASTERS.RECOMMENDATION.GET_RECOMMENDATION_CRITERIA, { headers });
  }
  /* [END] [CR000025] [03-Feb-2020] [Kartik] */

  public getRecommendationForUpdate(recommendationId: number): Observable<RecommendationMasterModel> {
    
    this._loaderMessageService.setLoaderMessage('Fetching recommendation details');
    const PARAMS = new HttpParams().set('recommendationId', recommendationId.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.get<RecommendationMasterModel>(RequestApi.MASTERS.RECOMMENDATION.GET_RECOMMENDATION, { params: PARAMS, headers });
  }

  public createOrUpdateRecommendation(recommendation: RecommendationMasterModel): Observable<APIResult> {
    
    this._loaderMessageService.setLoaderMessage('Saving recommendation details');
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.post<any>(RequestApi.MASTERS.RECOMMENDATION.UPSERT, recommendation, { headers });
  }

  public getRecommendationList(): Observable<any> {
    this._loaderMessageService.setLoaderMessage('Fetching recommendation records');
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.post<any>(RequestApi.MASTERS.RECOMMENDATION.GET_RECOMMENDATIONS, this.state, { headers });
  }

  public getAllRecommendation(): Observable<any> {
    this._loaderMessageService.setLoaderMessage('Fetching recommendation records');
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const state: State = {
      skip: 0,
      take: 2147483647,
    };
    return this._http.post<any>(RequestApi.MASTERS.RECOMMENDATION.GET_RECOMMENDATIONS, state, { headers });
  }

}
/* [END] [CR000022] [24-Jul-2019] [Kartik] */
